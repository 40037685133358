import { NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  standalone: true,
  imports: [NgStyle],
  selector: 'codecraft-hero-block',
  templateUrl: './hero-block.component.html',
  styleUrls: ['./hero-block.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class HeroBlockComponent {
  /**
   * Hero block background image URL
   */
  @Input() image: string;

  /**
   * Hero block background image color
   */
  @Input() color: string;

  getBgImage() {
    if (this.image) {
      return {
        'background-image': `url("${this.image}"`,
      };
    } else {
      return null;
    }
  }

  getBgColor() {
    if (this.color) {
      return {
        'background-color': `${this.color}`,
      };
    } else {
      return null;
    }
  }
}
