import { NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import {
  CcpcGameResult,
  GameProject,
  GameType,
  User,
} from '@codecraft-works/data-models';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faArrowLeft,
  faArrowRight,
  faCog,
  faPlay,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { GameDisplayGridComponent } from './game-display-grid.component';

interface Coordinate {
  row: number;
  column: number;
  src: string;
  alt: string;
}

@Component({
  standalone: true,
  selector: 'app-game-display',
  imports: [FontAwesomeModule, NgIf, RouterLink, GameDisplayGridComponent],
  template: `
    <app-game-display-grid
      #displayGrid
      *ngIf="this.gameProject"
      [gameProject]="this.gameProject"
      color="white"
      id="displayGrid"
    ></app-game-display-grid>
    <p class="lead p-2">
      Turn Number <span class="badge badge-info">{{ turnNumber }}</span
      ><span *ngIf="this.gameResults && this.showInvalid"
        >&nbsp;&nbsp;&nbsp;
        <span class="badge badge-pill badge-danger">Invalid Move</span>
      </span>
    </p>

    <h2
      *ngIf="this.gameResults && this.gameResults.winner && this.showWinner"
      class="p-2"
    >
      <ng-container *ngIf="gameResults.winnerGamePool; else nonGamePool">
        The winner is
        <a
          [routerLink]="['/battlescripts/arena/', gameResults.winnerGamePool]"
          >{{ gameResults.winner.trim() }}</a
        >
        <span *ngIf="gameType === 'tictactoe'">
          as {{ gameResults.game.winner.team }}
        </span>
        <span
          *ngIf="
            gameType === 'battleship' &&
            gameResults.player1GamePool === gameResults.player2GamePool
          "
        >
          (as
          {{
            gameResults.winner === gameResults.players[0] ? 'first' : 'second'
          }}
          player)
        </span>
      </ng-container>
      <ng-template #nonGamePool>
        The winner is {{ this.gameResults.winner }}
        <span *ngIf="this.gameType === 'tictactoe'">
          as {{ this.gameResults.game.winner.team }}</span
        >
      </ng-template>
    </h2>

    <div
      class="btn-group w-100 p-2"
      role="group"
      id="turnByTurnButtons"
      aria-label="Turn by Turn Buttons"
    >
      <button
        type="button"
        class="btn btn-lg btn-dark"
        [disabled]="true"
        (click)="firstTurn(this.gameProject.type)"
      >
        First Turn<br />
        <fa-icon [icon]="faAngleDoubleLeft" class="fa-lg"></fa-icon>
      </button>
      <button
        type="button"
        class="btn btn-lg btn-dark"
        [disabled]="true"
        (click)="previousTurn(this.gameProject.type)"
      >
        Prev Turn<br />
        <fa-icon [icon]="faArrowLeft" class="fa-lg"></fa-icon>
      </button>
      <button
        type="button"
        class="btn btn-lg btn-dark"
        [disabled]="true"
        (click)="playGame(this.gameProject.type)"
      >
        Play<br />
        <fa-icon [icon]="faPlay" class="fa-lg"></fa-icon>
      </button>
      <button
        type="button"
        class="btn btn-lg btn-dark"
        [disabled]="true"
        (click)="nextTurn(this.gameProject.type)"
      >
        Next Turn<br />
        <fa-icon [icon]="faArrowRight" class="fa-lg"></fa-icon>
      </button>
      <button
        type="button"
        class="btn btn-lg btn-dark"
        [disabled]="true"
        (click)="lastTurn(this.gameProject.type)"
      >
        Last Turn<br />
        <fa-icon [icon]="faAngleDoubleRight" class="fa-lg"></fa-icon>
      </button>
    </div>
  `,
  styles: [
    ':host { display: flex; justify-content: space-between; flex-direction: column; height: 100%; max-height: 100%; background-color: white;}',
  ],
})
export class GameDisplayComponent implements OnInit, AfterViewInit {
  @Input()
  gameProject: GameProject;

  @Input()
  user: User;

  @Input()
  language: string;

  @Input()
  gameType: GameType;

  @Output()
  isLoadingEmitter = new EventEmitter<boolean>();

  @Input()
  gameResults: any;

  @Input()
  tournamentRun = false;

  @Input()
  showWinner = true;

  showInvalid = false;

  buttonWidth: number;

  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;
  faAngleDoubleLeft = faAngleDoubleLeft;
  faAngleDoubleRight = faAngleDoubleRight;
  faUndo = faUndo;
  faCog = faCog;
  faPlay = faPlay;

  xSrc = '/assets/img/x.png';
  oSrc = '/assets/img/o.png';

  direction = 'vertical';

  turnNumber = 0;

  gameStates: any;

  // Battleship map data
  player1Map: any;
  player2Map: any;

  player1Name: string;
  player2Name: string;

  firstTimeLoading = true;

  @ViewChild('displayGrid')
  displayGrid!: GameDisplayGridComponent;

  loading: boolean;

  isTopScreen: boolean;

  displayingWin = false;

  constructor() {}

  runCode(gameResults: CcpcGameResult) {
    this.loading = true;
    this.isTopScreen = true;
    if (this.firstTimeLoading === false) {
      this.displayGrid.clearGrid();
    }
    this.gameResults = gameResults;
    this.setStates();
    this.firstTimeLoading = false;
    this.loading = false;
    this.isLoadingEmitter.emit(false);
  }

  ngOnInit(): void {
    if (this.tournamentRun) {
      this.gameProject = {
        type: this.gameType,
        code: null,
        result: null,
        language: this.language,
      } as GameProject;
    }
  }

  ngAfterViewInit(): void {
    if (this.tournamentRun && this.displayGrid && this.gameType) {
      this.firstTurn(this.gameType);
    }
  }

  setStates() {
    this.turnNumber = 0;

    if (
      this.gameResults &&
      this.gameProject.type === 'tictactoe' &&
      this.gameResults.game &&
      this.gameResults.game.gamestates
    ) {
      this.gameStates = this.gameResults.game.gamestates;
      this.ticTacToeInit();
    }

    if (
      this.gameResults &&
      this.gameProject.type === 'battleship' &&
      this.gameResults.moveData
    ) {
      this.gameStates = this.gameResults.moveData.toSpliced(
        0,
        0,
        this.gameResults.moveData[0]
      );
      this.player1Map = this.gameResults.player1PopMap;
      this.player2Map = this.gameResults.player2PopMap;
      this.player1Name = this.gameResults.players[0];
      this.player2Name = this.gameResults.players[1];
      this.battleshipInit();
    }
    const turnByTurnButtons = Array.from(
      document.getElementById('turnByTurnButtons').children
    );

    turnByTurnButtons.forEach((button) => {
      button.removeAttribute('disabled');
    });
  }

  // Converts a game state into placable coordinates for the grid component
  getGameStateCoordinates(turn: number): Coordinate[] {
    const currentState = this.gameStates[turn];
    let coordObject;
    const coordinates: Coordinate[] = [];
    for (let i = 0; i < currentState.length; i++) {
      for (let j = 0; j < currentState.length; j++) {
        if (currentState[i][j] === 'X') {
          coordObject = {
            row: i,
            column: j,
            src: this.xSrc,
            alt: 'X',
          };
          coordinates.push(coordObject);
        } else if (currentState[i][j] === 'O') {
          coordObject = {
            row: i,
            column: j,
            src: this.oSrc,
            alt: 'O',
          };
          coordinates.push(coordObject);
        }
      }
    }
    return coordinates;
  }

  ticTacToeInit() {
    const turn1State = this.getGameStateCoordinates(0);
    if (turn1State && turn1State.length > 0 && turn1State[0]) {
      this.displayGrid.placeItem({
        gridID: 1,
        row: turn1State[0].row,
        column: turn1State[0].column,
        image: {
          src: turn1State[0].src,
          alt: turn1State[0].alt,
        },
      });
    } else {
      this.showInvalid = true;
    }
  }

  placeShips(player: any, playerNumber: number) {
    const shipSrc = this.xSrc;
    const shipAlt = 'X';
    playerNumber === 1
      ? this.displayGrid.setTitle(`${this.player1Name}'s Board`, playerNumber)
      : this.displayGrid.setTitle(`${this.player2Name}'s Board`, playerNumber);

    // Check both players' board states and assign display objects to their locations
    for (let i = 0; i < player.length; i++) {
      for (let j = 0; j < player.length; j++) {
        // If this spot on the ship placement map isn't empty, there must be a ship in this cell
        if (player[i][j] !== 'EMPTY') {
          playerNumber === 1
            ? this.displayGrid.placeItem({
                gridID: 1,
                row: i,
                column: j,
                image: {
                  src: shipSrc,
                  alt: shipAlt,
                },
              })
            : this.displayGrid.placeItem({
                gridID: 2,
                row: i,
                column: j,
                image: {
                  src: shipSrc,
                  alt: shipAlt,
                },
              });
        }
      }
    }
  }

  // Turn 1 for Battleship
  battleshipInit() {
    // place ships for both players
    this.placeShips(this.player1Map, 1);
    this.placeShips(this.player2Map, 2);

    this.showInvalid = false;
  }

  // Changes turns for Battleship
  prevTurnBattleship() {
    // If a valid turn exists, determine whether or not a hit is registered, then remove a visual effect based on this.
    if (this.turnNumber > 0) {
      if (this.gameStates[this.turnNumber].isValidMove) {
        if (this.gameStates[this.turnNumber].isHit) {
          this.displayGrid.setBackgroundColor(
            this.gameStates[this.turnNumber].x,
            this.gameStates[this.turnNumber].y,
            this.isTopScreen ? 1 : 2,
            'white'
          );
        } else {
          this.displayGrid.removeItem(
            this.gameStates[this.turnNumber].x,
            this.gameStates[this.turnNumber].y,
            this.isTopScreen ? 1 : 2
          );
        }
      }

      // Cleanup: reduce the turn number, flip the screen order, check invalid moves
      this.turnNumber--;
      this.isTopScreen = !this.isTopScreen;
      this.showInvalid = !this.gameStates[this.turnNumber].isValidMove;
    } else {
      this.turnNumber = 0;
    }
  }

  // Displays the next turn on screen
  nextTurnBattleship() {
    // If a valid turn exists, determine whether or not a hit is registered, then apply a visual effect based on this.
    if (this.turnNumber < this.gameStates.length - 1) {
      // Increase the turn number, display on a different screen, check invalid moves
      this.turnNumber++;
      this.isTopScreen = !this.isTopScreen;
      this.showInvalid = !this.gameStates[this.turnNumber].isValidMove;

      // Check for hit
      if (this.gameStates[this.turnNumber].isValidMove) {
        if (this.gameStates[this.turnNumber].isHit) {
          this.displayGrid.setBackgroundColor(
            this.gameStates[this.turnNumber].x,
            this.gameStates[this.turnNumber].y,
            this.isTopScreen ? 1 : 2,
            'red'
          );
        } else {
          this.displayGrid.placeItem({
            gridID: this.isTopScreen ? 1 : 2,
            row: this.gameStates[this.turnNumber].x,
            column: this.gameStates[this.turnNumber].y,
            image: {
              src: '/assets/img/o.png',
              alt: 'Miss',
            },
          });
        }
      }
    } else {
      this.turnNumber = this.gameStates.length - 1;
    }
  }

  firstTurnBattleship() {
    this.isTopScreen = true;
    this.showInvalid = false;
    this.displayGrid.clearGrid();
    this.setStates();
  }

  lastTurnBattleship() {
    while (this.turnNumber < this.gameStates.length - 1) {
      this.nextTurnBattleship();
    }
  }

  timer(v) {
    return new Promise((r) => setTimeout(r, v));
  }

  async playBattleship() {
    while (this.turnNumber < this.gameStates.length - 1) {
      this.nextTurnBattleship();
      await this.timer(500);
    }
  }

  async playTicTacToe() {
    while (this.turnNumber < this.gameStates.length - 1) {
      this.nextTurnTicTacToe();
      await this.timer(500);
    }
  }

  firstTurnTicTacToe() {
    this.displayGrid.clearGrid();
    this.showInvalid = false;
    this.setStates();
  }

  prevTurnTicTacToe() {
    if (this.turnNumber > 0) {
      this.turnNumber--;
      let previousTurn: Coordinate[] = null;
      if (this.turnNumber > 0) {
        previousTurn = this.getGameStateCoordinates(this.turnNumber - 1);
      }
      const currentTurn = this.getGameStateCoordinates(this.turnNumber);
      this.displayGrid.clearGrid();
      this.highlightWin();

      if (JSON.stringify(previousTurn) === JSON.stringify(currentTurn)) {
        this.showInvalid = true;
      } else {
        this.showInvalid = false;
      }

      for (const item of currentTurn) {
        this.displayGrid.placeItem({
          gridID: 1,
          row: item.row,
          column: item.column,
          image: {
            src: item.src,
            alt: item.alt,
          },
        });
      }
    } else {
      this.turnNumber = 0;
    }
  }

  nextTurnTicTacToe() {
    if (this.turnNumber < this.gameStates.length - 1) {
      let previousTurn: Coordinate[] = null;
      if (this.turnNumber > 0) {
        previousTurn = this.getGameStateCoordinates(this.turnNumber);
      }
      this.turnNumber++;
      const currentTurn = this.getGameStateCoordinates(this.turnNumber);
      this.highlightWin();

      if (JSON.stringify(previousTurn) === JSON.stringify(currentTurn)) {
        this.showInvalid = true;
      } else {
        this.showInvalid = false;
      }

      for (const item of currentTurn) {
        this.displayGrid.placeItem({
          gridID: 1,
          row: item.row,
          column: item.column,
          image: {
            src: item.src,
            alt: item.alt,
          },
        });
      }
    } else {
      this.turnNumber = this.gameStates.length - 1;
    }
  }

  lastTurnTicTacToe() {
    while (this.turnNumber < this.gameStates.length - 1) {
      this.nextTurnTicTacToe();
    }
  }

  highlightWin() {
    const finalBoard = this.gameStates[this.turnNumber];
    let winningSpaces;
    let playerHasWon = false;

    // Gets coordinates to highlight for each possible win
    for (let i = 0; i < 3; i++) {
      if (
        finalBoard[i][0] === finalBoard[i][1] &&
        finalBoard[i][1] === finalBoard[i][2] &&
        finalBoard[i][0] !== ' '
      ) {
        winningSpaces = [
          [i, 0],
          [i, 1],
          [i, 2],
        ];
        playerHasWon = true;
      } else if (
        finalBoard[0][i] === finalBoard[1][i] &&
        finalBoard[1][i] === finalBoard[2][i] &&
        finalBoard[0][i] !== ' '
      ) {
        winningSpaces = [
          [0, i],
          [1, i],
          [2, i],
        ];
        playerHasWon = true;
      }
    }
    if (
      finalBoard[0][0] === finalBoard[1][1] &&
      finalBoard[1][1] === finalBoard[2][2] &&
      finalBoard[0][0] !== ' '
    ) {
      winningSpaces = [
        [0, 0],
        [1, 1],
        [2, 2],
      ];
      playerHasWon = true;
    } else if (
      finalBoard[2][0] === finalBoard[1][1] &&
      finalBoard[1][1] === finalBoard[0][2] &&
      finalBoard[2][0] !== ' '
    ) {
      winningSpaces = [
        [2, 0],
        [1, 1],
        [0, 2],
      ];
      playerHasWon = true;
    }

    // If a player has won this turn, set the winning coordinates to a different color
    if (playerHasWon && !this.displayingWin) {
      for (let i = 0; i < 3; i++) {
        this.displayGrid.setBackgroundColor(
          winningSpaces[i][0],
          winningSpaces[i][1],
          1,
          'green'
        );
        this.displayingWin = true;
      }
      // If the player backs away from the last turn, stop highlighting
    } else if (this.displayingWin) {
      // this.displayGrid.resetBackground();
      this.displayingWin = false;
    }

    return playerHasWon;
  }

  firstTurn(gameType: GameType) {
    if (gameType === 'battleship') {
      this.firstTurnBattleship();
    } else if (gameType === 'tictactoe') {
      this.firstTurnTicTacToe();
    }
  }

  previousTurn(gameType: GameType) {
    if (gameType === 'battleship') {
      this.prevTurnBattleship();
    } else if (gameType === 'tictactoe') {
      this.prevTurnTicTacToe();
    }
  }

  playGame(gameType: GameType) {
    if (gameType === 'battleship') {
      this.playBattleship();
    } else if (gameType === 'tictactoe') {
      this.playTicTacToe();
    }
  }

  nextTurn(gameType: GameType) {
    if (gameType === 'battleship') {
      this.nextTurnBattleship();
    } else if (gameType === 'tictactoe') {
      this.nextTurnTicTacToe();
    }
  }

  lastTurn(gameType: GameType) {
    if (gameType === 'battleship') {
      this.lastTurnBattleship();
    } else if (gameType === 'tictactoe') {
      this.lastTurnTicTacToe();
    }
  }
}
