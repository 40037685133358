export class CcpcGameResults {
  exitCode: number;
  exitMessage?: string;
  consoleOutput?: string;
  winner?: string;
  players?: string[];
}

export type CcpcGameResult = CcpcGameResultBattleship | CcpcGameResultTicTacToe;

// Tic-Tac-Toe Models

export type CcpcGameResultTicTacToe = CcpcGameResults & {
  game?: CcpcTicTacToeGame;
};

export interface CcpcTicTacToeGame {
  players: {[key: string]: Winner};
  Board: Array<Team[]>;
  isPlayer1Turn: boolean;
  gameOver: boolean;
  winner: Winner;
  player1Time: number;
  player2Time: number;
  turnNumber: number;
  winningTimeDifference: number;
  gamestates: Array<Array<Team[]>>;
  p1AverageTime: number;
  p2AverageTime: number;
}

export enum Team {
  Empty = ' ',
  O = 'O',
  X = 'X',
}

export interface Winner {
  Board: Array<Team[]>;
  team: Team;
  Xcoor: number;
  Ycoor: number;
  name: string;
  Moves: Array<number[]>;
  numOfInvalidMoves: number;
}

// Battleship Models

export type CcpcGameResultBattleship = CcpcGameResults & {
  moveData?: TurnData[];
  player1PopMap?: Array<MapStates[]>;
  player2PopMap?: Array<MapStates[]>;
};

export interface TurnData {
  x: number;
  y: number;
  player: string;
  playerNumber: number;
  isHit: boolean;
  isValidMove: boolean;
}

export enum MapStates {
  Battleship = 'BATTLESHIP',
  Carrier = 'CARRIER',
  Cruiser = 'CRUISER',
  Destroyer = 'DESTROYER',
  Empty = 'EMPTY',
  Submarine = 'SUBMARINE',
}
