<h1 class="mb-2 display-3">CCPC Submission</h1>
<div
  class="mb-2"
  *ngIf="(currentSubmission$ | async) as submission; else noSubmission"
>
  <h2 class="display-4">Currently Submitted Project</h2>
  <div *ngIf="submission.projectType === 'scratch'; else gameWebProject">
    <p>
      <a
        [href]="submission.scratchUrl"
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        Project URL: {{ submission.scratchUrl }} | Submitted:
        {{ submission.submitted.toDate() | date: 'medium' }}
      </a>
    </p>
    <iframe
      title="CCPC Project"
      [src]="submission.scratchUrl + '/embed' | safeURL"
      allowtransparency="true"
      width="485"
      height="402"
      frameborder="0"
      scrolling="no"
      allowfullscreen="false"
    ></iframe>
  </div>

  <ng-template #gameWebProject>
    <p>
      <a
        [routerLink]="
          submission.projectType === 'web'
            ? ['/web', submission.projectSubmission.id || null]
            : ['/battlescripts', 'projects', submission.projectSubmission.id || null]
        "
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        Project Name: {{ submission.projectSubmission.name }} | Submitted:
        {{ submission.submitted.toDate() | date: 'medium' }}
      </a>
    </p>
  </ng-template>

  <p>
    Click the button below to submit a new project or resubmit. Changes made to
    a project after submission will not be included, so please resubmit if you
    have made changes to your project.
  </p>
</div>

<ng-template #noSubmission>
  <h2 class="display-4 mb-2">No Project Currently Submitted</h2>
</ng-template>

<ng-container *ngIf="(tournament$ | async) as tournament">
  <ng-container
    *ngIf="
      (myProjectData$ | async)?.length > 0 || tournament.type === 'scratch';
      else noProjectsFound
    "
  >
    <button
      role="button"
      class="btn btn-primary"
      (click)="openModal(submissionModal)"
    >
      Submit
      <abbr title="Codecraft Computer Programming Competitions">CCPC</abbr>
      Project
    </button>

    <ng-template #submissionModal>
      <app-ccpc-submission-form-modal
        *ngIf="currentSubmission$ && user"
        [currentSubmission$]="currentSubmission$"
        [myProjectData$]="myProjectData$"
        [user]="user"
        [tournament]="tournament"
        (closeModal)="onClose()"
      ></app-ccpc-submission-form-modal>
    </ng-template>
  </ng-container>

  <ng-template #noProjectsFound>
    <h2 class="display-4">
      There were no {{ tournament.type | titlecase }} Projects found
    </h2>
    <p>Please create a {{ tournament.type }} project to compete.</p>
  </ng-template>
</ng-container>
