import { Project } from '../project/project.model';

export enum GameType {
  TICTACTOE = 'tictactoe',
  BATTLESHIP = 'battleship'
}

export enum GameLanguage {
  PYTHON = 'python',
  JAVA = 'java',
  BLOCKLY = 'blockly',
  JAVASCRIPT = 'javascript'
}

/**
 * @classdesc Class representing a game project
 */

export class GameProject extends Project {
  /**
   * Stores the type of game the user is editing
   */
  type: GameType;

  /**
   * Stores the code the user inputs
   */
  code: string;

  /**
   * Stores the results of the last game
   */
  result: string;

  /**
   * Stores info on the language the project is in
   */
  language: string;

  /**
   * Stores the compiled code from the user input
   */
  compiledCode?: string;
}
