<a
  *ngIf="submission.projectType === 'scratch'; else gameWebProject"
  [href]="submission.scratchUrl"
  rel="noopener noreferrer"
  target="_blank"
>
  {{ submission.scratchUrl }}
</a>

<ng-template #gameWebProject>
  <a
    [routerLink]="
      submission.projectType === 'web'
        ? ['/web', submission.projectSubmission.id || null]
        : ['/battlescripts', 'projects', submission.projectSubmission.id || null]
    "
    rel="noopener noreferrer"
    target="_blank"
  >
    {{ submission.projectSubmission.name }}
  </a>
</ng-template>
