<div class="modal-header bg-primary text-light">
  <h4 class="modal-title pull-left">
    <fa-icon
      [icon]="faCloudUploadAlt"
      class="fa-sm"
    ></fa-icon>&nbsp; Submit
    {{ tournament.type | titlecase }} Project
  </h4>
  <button
    type="button"
    class="close pull-right text-white"
    aria-label="Close"
    id="userSettingsModalClose"
    (click)="onModalClose()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div
    id="userRolesHelp"
    class="alert alert-success"
  >
    <strong>IMPORTANT: You may re-submit your project as many times as you like
      before the due date. If you make changes to your project, you MUST
      resubmit your project if you want those changes to be submitted.</strong>
  </div>

  <div
    class="mb-2"
    *ngIf="(currentSubmission$ | async) as currentSubmission"
  >
    <h5>Currently Submitted Project</h5>
    <div *ngIf="tournament.type === 'scratch'; else gameWebProject">
      <p>
        <a
          [href]="currentSubmission.scratchUrl"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          Project URL: {{ currentSubmission.scratchUrl }} | Submitted:
          {{ currentSubmission.submitted.toDate() | date: 'medium' }}
        </a>
      </p>
    </div>

    <ng-template #gameWebProject>
      <p>
        <a
          [routerLink]="
            tournament.type === 'web'
              ? ['/web', currentSubmission.projectSubmission.id || null]
              : [
                  '/battlescripts',
                  'projects',
                  currentSubmission.projectSubmission.id || null
                ]
          "
          target="_blank"
          (click)="onModalClose()"
        >
          Project Name: {{ currentSubmission.projectSubmission.name }} |
          Submitted:
          {{ currentSubmission.submitted.toDate() | date: 'medium' }}
        </a>
      </p>
    </ng-template>
  </div>
  <form
    id="projectSubmissionForm"
    [formGroup]="submissionForm"
  >
    <div class="form-group row">
      <label
        for="entitySelect"
        class="col-sm-4 col-form-label"
      >Select Coach</label>
      <div class="col-sm-8">
        <select
          formControlName="entitySelect"
          id="entitySelect"
          class="form-control"
          aria-describedby="entitySelectHelp"
        >
          <option
            *ngFor="let entity of tournament.entities"
            [ngValue]="entity"
          >
            {{ entity.organization ? entity.organization + ' | ' : null }}
            {{ entity.name | titlecase }}
          </option>
        </select>

        <small
          id="entitySelectHelp"
          class="form-text text-muted"
        >
          Choose your coach
        </small>
      </div>
    </div>
    <hr />
    <div class="form-group row">
      <label
        for="studentName"
        class="col-sm-4 col-form-label"
      >Student Name</label>
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          id="studentName"
          formControlName="studentName"
          aria-describedby="studentNameHelp"
          [attr.aria-invalid]="studentName.invalid"
          required
          [ngClass]="{
            'is-invalid': studentName.invalid,
            'is-valid': studentName.valid && studentName.dirty
          }"
        />
        <small
          id="studentNameHelp"
          class="form-text text-muted"
        >
          This is the name that will appear in any official awards or rankings.
        </small>
      </div>
    </div>
    <div class="form-check mb-2">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        [checked]="isInTeam"
        (change)="toggleTeam()"
      />
      <label
        class="form-check-label"
        for="flexCheckDefault"
      >
        Working in a team
      </label>
    </div>
    <div
      *ngIf="isInTeam"
      class="form-group row"
    >
      <label
        for="studentName"
        class="col-sm-4 col-form-label"
      >Team Member Name</label>
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          id="teammateName"
          formControlName="teammateName"
          aria-describedby="teammateNameHelp"
          [attr.aria-invalid]="teammateName.invalid"
          required
          [ngClass]="{
            'is-invalid': teammateName.invalid,
            'is-valid': teammateName.valid && teammateName.dirty
          }"
        />
        <small
          id="teammateNameHelp"
          class="form-text text-muted"
        >
          This is the name of your team member that will appear in any official
          awards or rankings.
        </small>
      </div>
    </div>
    <hr />
    <div class="form-group row">
      <label
        for="projectList"
        class="col-sm-4 col-form-label"
      >{{ tournament.type | titlecase }} Project
      </label>

      <ng-container *ngIf="tournament.type === 'scratch'; else gameWebProject">
        <div class="col-sm-8">
          <input
            type="text"
            class="form-control"
            id="scratchProject"
            formControlName="scratchProject"
            placeholder="Example URL https://scratch.mit.edu/projects/12345678"
            aria-describedby="scratchProjectHelp"
            [attr.aria-invalid]="scratchProject.invalid"
            required
            [ngClass]="{
              'is-invalid': scratchProject.invalid,
              'is-valid': scratchProject.valid && scratchProject.dirty
            }"
          />
          <p
            id="scratchProjectHelp"
            class="form-text text-muted"
          >
            <small>
              Paste your scratch project URL here, but before you do, the
              student will need to have “Shared” the project on Scratch so
              judges will be able to view it.(Need more info on sharing your
              Scratch project? Read
              <a
                rel="noopener noreferrer nofollow"
                target="_blank"
                href="https://en.scratch-wiki.info/wiki/Project_Sharing"
              >this</a>)
            </small>
          </p>
          <p
            *ngIf="scratchProject.invalid"
            id="scratchProjectInvalid"
            class="form-text text-muted"
          >
            The URL must look like this:
            <code>https://scratch.mit.edu/projects/12345678</code>
          </p>
        </div>
      </ng-container>
      <ng-template #gameWebProject>
        <div
          *ngIf="!projectSelectionMade; else chooseAgain"
          class="col-sm-8"
        >
          <input
            role="combobox"
            autocomplete="off"
            type="text"
            class="form-control my-class"
            id="projectList"
            name="projectList"
            formControlName="projectList"
            aria-describedby="projectSelectHelp"
            id="projectSelection"
            [isAnimated]="true"
            [(ngModel)]="projectItemSelected"
            [typeaheadAsync]="true"
            [typeahead]="filteredProjectData$"
            [typeaheadOptionsLimit]="10"
            [typeaheadMinLength]="1"
            [typeaheadItemTemplate]="customItemTemplate"
            placeholder="Start typing to find project"
            (typeaheadOnSelect)="onProjectSelect($event)"
            (typeaheadNoResults)="typeaheadNoResults($event)"
            editable="false"
          />
          <small
            id="projectSelectHelp"
            class="form-text text-muted"
          >

            Start typing the title of your {{ tournament.type }} project to see
            it appear in the list. You can also look at all
            <a
              href="/"
              target="_blank"
            >
              My {{ tournament.type | titlecase }} Projects in a new tab</a>.
          </small>
          <div
            class="alert alert-danger"
            *ngIf="noResult"
          >
            No Results Found
          </div>
          <ng-template
            #customItemTemplate
            class="container-fluid"
            let-model="item"
            let-index="index"
          >
            <div class="row">
              <div class="col-10 my-auto text-wrap">
                {{ model.name + ' | ' }} Modified date:
                {{ model.modified.toDate() | date: 'medium' }}
              </div>
            </div>
          </ng-template>

          <small
            id="projectSelectHelp"
            class="form-text text-muted"
          > </small>
        </div>
        <ng-template #chooseAgain>
          <div class="row mb-3 mx-3">
            <div class="col-7 my-auto text-wrap">
              {{ projectSelected.name + ' | ' }} Modified date:
              {{ projectSelected.modified.toDate() | date: 'medium' }}
            </div>
            <button
              type="button"
              class="btn btn-primary col-3 py-1 align-self-center"
              (click)="chooseAnotherProject()"
            >
              Choose again
            </button>
          </div>
        </ng-template>
      </ng-template>
    </div>

    <div class="modal-footer">
      <div class="btn-group">
        <button
          [disabled]="submissionForm.invalid"
          type="button"
          class="btn btn-primary"
          (click)="submitForm()"
        >
          Submit
        </button>
        <button
          type="button"
          class="btn btn-light"
          id="userSettingsModalCloseButton"
          (click)="onModalClose()"
        >
          Cancel
        </button>
      </div>
    </div>

    <ng-template #successModal>
      <div class="modal-header bg-primary text-light">
        <h4 class="modal-title pull-left">Submission Successful</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="successModalRef.hide()"
        >
          <span
            aria-hidden="true"
            class="text-light"
          >&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Project submitted! You may resubmit any project as many times as you
        need before the due date.
        {{
        tournament.type === 'scratch'
        ? 'PLEASE DOUBLE CHECK THAT THE SCRATCH PROJECT IS SHARED - you will
        be disqualified if the judges cannot view your project.'
        : ''
        }}
      </div>
      <div class="modal-footer">
        <button
          type="submit"
          class="btn btn-success"
          (click)="successModalRef.hide()"
        >
          Dismiss
        </button>
      </div>
    </ng-template>

    <ng-template #failModal>
      <div class="modal-header bg-primary text-light">
        <h4 class="modal-title pull-left">
          Submission Failed
        </h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="failModalRef.hide()"
        >
          <span
            aria-hidden="true"
            class="text-light"
          >&times;</span>
        </button>
      </div>
      <div class="modal-body">
        There was an error submitting your project
      </div>
      <div class="modal-footer">
        <button
          type="submit"
          class="btn btn-success"
          (click)="failModalRef.hide()"
        >
          Dismiss
        </button>
      </div>
    </ng-template>
  </form>
</div>
