import {
  AfterViewInit,
  Component,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { GameProjectService } from '@app/game/data-access/game-project.service';
import { WebProjectService } from '@app/shared/web-project/web-project.service';
import {
  CcpcSubmission,
  CcpcSubmissionType,
  CcpcTournament,
  Project,
  User,
} from '@codecraft-works/data-models';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CcpcService } from '../ccpc.service';

@Component({
  selector: 'app-ccpc-submission-form',
  templateUrl: './ccpc-submission-form.component.html',
  styleUrls: ['./ccpc-submission-form.component.css'],
})
export class CcpcSubmissionFormComponent implements AfterViewInit {
  @Input()
  user: User;

  @Input()
  tournamentId: string;

  tournament$: Observable<CcpcTournament>;

  @Input()
  competitionId: string;

  isSubmissionDeadlineDatePassed: boolean;

  @ViewChild('submissionModal') submissionModal;

  currentSubmission$: Observable<CcpcSubmission>;

  myProjectData$: Observable<Project[]>;

  modalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private ccpcService: CcpcService,
    private webProjectService: WebProjectService,
    private gameProjectService: GameProjectService
  ) {}

  ngAfterViewInit(): void {
    // Check and set currentSubmission observable
    this.initCurrentSubmission();

    if (this.competitionId && this.tournamentId) {
      this.tournament$ = this.ccpcService
        .getTournament(this.competitionId, this.tournamentId)
        .pipe(
          // Get user projects related to tournament type for form selection
          tap((tournament) => {
            if (tournament && tournament.type) {
              switch (tournament.type) {
                case CcpcSubmissionType.WEB:
                  this.myProjectData$ = this.webProjectService.getWebProjects(
                    this.user.uid
                  );
                  break;
                case CcpcSubmissionType.TICTACTOE_JAVA:
                  this.myProjectData$ = this.gameProjectService.getGameProjects(
                    {
                      language: 'java',
                      game: 'tictactoe',
                      uid: this.user.uid,
                    }
                  );
                  break;
                case CcpcSubmissionType.TICTACTOE_PYTHON:
                  this.myProjectData$ = this.gameProjectService.getGameProjects(
                    {
                      language: 'python',
                      game: 'tictactoe',
                      uid: this.user.uid,
                    }
                  );
                  break;
                case CcpcSubmissionType.TICTACTOE_BLOCKLY:
                  this.myProjectData$ = this.gameProjectService.getGameProjects(
                    {
                      language: 'blockly',
                      game: 'tictactoe',
                      uid: this.user.uid,
                    }
                  );
                  break;
                case CcpcSubmissionType.BATTLESHIP_JAVA:
                  this.myProjectData$ = this.gameProjectService.getGameProjects(
                    {
                      language: 'java',
                      game: 'battleship',
                      uid: this.user.uid,
                    }
                  );
                  break;
                default:
                  break;
              }
            }
          })
        );
    }
  }

  openModal(modalName: TemplateRef<any>, modalClass = 'modal-xl') {
    this.modalRef = this.modalService.show(modalName, {
      class: `${modalClass} modal-dialog modal-dialog-centered`,
      animated: true,
    });
  }

  onClose(): void {
    // Check and set currentSubmission observable
    this.initCurrentSubmission();
    this.modalRef.hide();
  }

  initCurrentSubmission() {
    // Check and set currentSubmission observable
    if (this.user && this.user.uid && this.competitionId && this.tournamentId) {
      this.currentSubmission$ = this.ccpcService.getSubmission(
        this.user.uid,
        this.competitionId,
        this.tournamentId
      );
    }
  }
}
