import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FaIconComponent,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import {
  faCircle,
  faToggleOff,
  faToggleOn,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  standalone: true,
  imports: [FontAwesomeModule, CommonModule],
  selector: 'app-navbar-item',
  template: `
    <div
      class="nav-item"
      [attr.data-item]="dataItem"
      [attr.aria-label]="ariaLabel"
      [ngStyle]="computedStyles"
    >
      <fa-icon
        *ngIf="faIcon && !isToggle"
        [icon]="faIcon"
        [spin]="iconSpin"
        [fixedWidth]="true"
        transform="shrink-6"
        class="align-middle"
      >
        <span
          *ngIf="toolTipText"
          class="tooltip-wrapper"
          data-toggle="tooltip"
          [title]="toolTipText"
        ></span>
      </fa-icon>
      <fa-icon
        *ngIf="isToggle"
        [icon]="toggleState ? faToggleOn : faToggleOff"
        [fixedWidth]="true"
        class="align-middle"
      >
        <span
          *ngIf="toolTipText"
          class="tooltip-wrapper"
          data-toggle="tooltip"
          [title]="toolTipText"
        ></span>
      </fa-icon>
      <span
        *ngIf="image"
        [ngClass]="{
          'rounded-circle-image-wrapper': image && isImageCircle,
          'image-wrapper': image && !isImageCircle
        }"
      >
        <img
          [src]="image"
          [alt]="alt ? alt : label ? label : null"
          [title]="toolTipText ? toolTipText : null"
          class="align-middle"
          [ngClass]="{ 'rounded-circle': isImageCircle }"
        />
      </span>
      <div
        *ngIf="label"
        class="icon-label"
        [ngClass]="{ 'd-inline': showLabel, 'd-none': !showLabel }"
      >
        {{ label }}
      </div>
      <ng-content
        [ngClass]="{ 'd-inline': showLabel, 'd-none': !showLabel }"
      ></ng-content>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        text-align: left;
        cursor: pointer;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 0.25rem;
        font-family: bonum;
      }

      :host(:hover) {
        background-color: var(--light) !important;
        color: var(--primary) !important;
      }

      :host(:active) {
        background-color: var(--secondary) !important;
        color: var(--primary) !important;
      }

      :host(:disabled) {
        pointer-events: none;
        color: gray;
      }

      .icon-label {
        width: fit-content;
        white-space: nowrap;
      }

      fa-icon {
        font-size: 32px;
        width: 48px;
      }

      .rounded-circle-image-wrapper,
      .image-wrapper {
        width: 32;
        height: 32;
        display: inline-block;
        margin: 8px 4px;
      }

      img {
        width: 32px;
        height: 32px;
        object-fit: contain;
      }

      .rounded-circle-image-wrapper > img {
        width: 32px;
        height: 32px;
        object-fit: contain;
      }
    `,
  ],
})
export class NavbarItemComponent implements OnInit {
  @Input()
  dataItem: string;

  @Input()
  ariaLabel: string;

  @Input()
  faIcon: FaIconComponent;

  @Input()
  iconSpin = false;

  @Input()
  image: string;

  @Input()
  alt: string;

  @Input()
  isImageCircle = false;

  @Input()
  label: string;

  @Input()
  showLabel = true;

  @Input()
  toolTipText: string;

  @Input()
  isToggle = false;

  @Input()
  toggleState: boolean;

  faCircle = faCircle;
  faToggleOn = faToggleOn;
  faToggleOff = faToggleOff;

  computedStyles = {};

  constructor() {}

  ngOnInit(): void {
    if (this.isToggle && this.toggleState) {
      this.computedStyles['margin-left'] = '8px';
    }
  }
}
