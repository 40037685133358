import { CommonModule, TitleCasePipe } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { GameProjectService } from '@app/game/data-access/game-project.service';
import { InstructorService } from '@app/shared/instructor/instructor.service';
import { LocationService } from '@app/shared/location/location.service';
import { ProgramService } from '@app/shared/program/program.service';
import { SharedModule } from '@app/shared/shared.module';
import { StudentService } from '@app/shared/student/student.service';
import { TabOrderDirective } from '@app/shared/tab-order/tab-order.directive';
import { WebProjectService } from '@app/shared/web-project/web-project.service';
import { SharedComponentsModule } from '@codecraft-works/shared-components';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AnalyticsService } from './analytics/analytics.service';
import { AuthService } from './auth/auth.service';
import { SessionStorageService } from './auth/session-storage.service';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { MembershipGuard } from './guards/membership.guard';
import { UserExistsGuard } from './guards/user-exists.guard';
import { HomePageComponent } from './home-page/home-page.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LoginButtonComponent } from './login/login-button/login-button.component';
import { LoginPageComponent } from './login/login-page/login-page.component';
import { LoginWithEmailComponent } from './login/login-with-email/login-with-email.component';
import { MembershipAddStudentFormComponent } from './membership/membership-add-student-form/membership-add-student-form.component';
import { MembershipAssignmentFormComponent } from './membership/membership-assignment-form/membership-assignment-form.component';
import { MembershipDetailsComponent } from './membership/membership-details/membership-details.component';
import { MembershipListComponent } from './membership/membership-list/membership-list.component';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { PermissionDeniedComponent } from './permission-denied/permission-denied.component';
import { ShoppingCartItemComponent } from './shopping-cart/shopping-cart-item/shopping-cart-item.component';
import { ShoppingCartPageComponent } from './shopping-cart/shopping-cart-page/shopping-cart-page.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { TextFormatterService } from './text-formatter/text-formatter.service';
import { UserProfilePageComponent } from './user/user-profile-page/user-profile-page.component';
import { UserSettingsFormComponent } from './user/user-settings-form/user-settings-form.component';
import { UserSettingsPageComponent } from './user/user-settings-page/user-settings-page.component';

@NgModule({
  imports: [
    RouterModule,
    SharedModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    TabsModule.forRoot(),
    SharedComponentsModule,
  ],
  exports: [
    LoginButtonComponent,
    LoginPageComponent,
    HomePageComponent,
    UserSettingsFormComponent,
    MembershipAssignmentFormComponent,
    MembershipAddStudentFormComponent,
  ],
  declarations: [
    LoginButtonComponent,
    LoginPageComponent,
    HomePageComponent,
    LoginWithEmailComponent,
    UserSettingsFormComponent,
    PermissionDeniedComponent,
    LandingPageComponent,
    ShoppingCartPageComponent,
    ShoppingCartItemComponent,
    ShoppingCartComponent,
    UserProfilePageComponent,
    UserSettingsPageComponent,
    TabOrderDirective,
    MembershipDetailsComponent,
    MembershipListComponent,
    MembershipAssignmentFormComponent,
    MembershipAddStudentFormComponent,
  ],
  providers: [
    AuthGuard,
    AdminGuard,
    AuthService,
    TextFormatterService,
    ProgramService,
    StudentService,
    InstructorService,
    LocationService,
    AngularFireDatabase,
    WebProjectService,
    TitleCasePipe,
    UserExistsGuard,
    AnalyticsService,
    MembershipGuard,
    SessionStorageService,
    GameProjectService,
    { provide: 'windowObject', useValue: window },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
